import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

declare global {
  var ENV:
    | {
        SENTRY_ENV: string;
      }
    | undefined;
}

const SENTRY_ENV = globalThis.ENV?.SENTRY_ENV;

if (SENTRY_ENV) {
  Sentry.init({
    dsn: "https://8c8a106aae3fd39eb3d0683dafb39230@o362985.ingest.us.sentry.io/4507270303776768",
    environment: SENTRY_ENV,

    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
